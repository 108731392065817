import { render, staticRenderFns } from "./DataTables.vue?vue&type=template&id=9a74f258&"
import script from "./DataTables.vue?vue&type=script&lang=js&"
export * from "./DataTables.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VContainer,VDataTable,VDivider,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("D:\\_Dr\\_git\\poliglotiki-front\\crm-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9a74f258')) {
      api.createRecord('9a74f258', component.options)
    } else {
      api.reload('9a74f258', component.options)
    }
    module.hot.accept("./DataTables.vue?vue&type=template&id=9a74f258&", function () {
      api.rerender('9a74f258', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dashboard/tables/DataTables.vue"
export default component.exports