var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "data-tables", tag: "section" } },
    [
      _c("base-v-component", {
        attrs: { heading: "Data Tables", link: "components/data-tables" }
      }),
      _c(
        "base-material-card",
        {
          staticClass: "px-5 py-3",
          attrs: { color: "indigo", icon: "mdi-vuetify", inline: "" },
          scopedSlots: _vm._u([
            {
              key: "after-heading",
              fn: function() {
                return [
                  _c("div", { staticClass: "display-2 font-weight-light" }, [
                    _vm._v("\n        DataTables\n      ")
                  ])
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("v-text-field", {
            staticClass: "ml-auto",
            staticStyle: { "max-width": "250px" },
            attrs: {
              "append-icon": "mdi-magnify",
              label: "Search",
              "hide-details": "",
              "single-line": ""
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          }),
          _c("v-divider", { staticClass: "mt-3" }),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.items,
              search: _vm.search,
              "sort-by": ["name", "office"],
              "sort-desc": [false, true],
              "multi-sort": ""
            },
            on: {
              "update:search": function($event) {
                _vm.search = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }